import React from 'react';
import styled from 'styled-components';
import loadable from "@loadable/component"
import '../../styles/globals.css';
import { Link, graphql } from 'gatsby';
import { MetaComponent } from '../../components';
import {
  Button,
  SassyText,
  Title,
  Section,
  Text,
  Divider,
  SectionWrapper,
} from '../../components/core';
import { useMixpanel } from '../../context/MixpanelContext';

import Bullet from '../../assets/images/bullet.svg';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import useHindiLangFont from '../../hooks/useHindiLangFont';
import { LeadFormProvider } from '../../context/LeadFormContext';

const Footer = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Footer,
});
const LeadForm = loadable(() => import("../../components/LeadForm"));
const Navbar = loadable(() => import("../../components"), {
  resolveComponent: (components) => components.Navbar,
});

const Coverage = loadable(() => import("../../components/Coverage"));
const HelpSection = loadable(() => import("../../components/HelpSection"));
const PolicyHeader = loadable(() => import("../../components/PolicyHeader"));
const VideoSection = loadable(() => import("../../components/VideoSection"));

const BLUS = () => {
  const {hindiBlack, hindiBold, hindiRegular} = useHindiLangFont()
  const {t} = useTranslation(['blus', 'common'])
  const mixpanel = useMixpanel()
  const [isQuoteModalOpen, setIsQuoteModalOpen] = React.useState(false);

  const handleQuoteModalOpen = () => {
    setIsQuoteModalOpen(true);
  };

  const handleQuoteModalClose = () => {
    setIsQuoteModalOpen(false);
  };

  const handleGetQuoteClick = (mxEventName = "") => {
    if (mxEventName) mixpanel.track(mxEventName)
    handleQuoteModalOpen()
  }

  return (
    <>
      <LeadFormProvider>
        {isQuoteModalOpen ? <LeadForm onModalClose={handleQuoteModalClose} /> : null}
      </LeadFormProvider>
      <MetaComponent
        title="Bharat Laghu Udyam Suraksha (BLUS) Policy - Verak"
        description="Cover your business against fire and allied risks like explosion, storms, strikes, earthquake,etc. through a BLUS policy. Get a quote now."
        image="blus"
        url="fire/blus"
      />
      <Navbar active="products" />
      <PolicyHeader
        acronym="BLUS"
        title={
          <>
            <SassyText className={hindiBlack}>{t('HeroSection.Title')} </SassyText>(BLUS)
          </>
        }
        content={
          <span className={hindiRegular}>
            <Trans i18nKey="HeroSection.Desc1">
              Bharat Laghu Udyam Suraksha (BLUS) is a{' '}
              <strong className={hindiBold}> Fire and Allied Perils Insurance product </strong>{' '}
              available in India that provides coverage to SMEs and MSMEs against
              a broad scope or risks like fire, explosion, natural calamities, and
              terrorism, among many others.
            </Trans>
          </span>
        }
        subcontent={
          <span className={hindiRegular}>
            <Trans i18nKey="HeroSection.Desc2" style={{fontWeight: "700"}}>
              You can purchase a BLUS policy if your business’s total insurable
              assets at any
              <SassyText className={hindiBold}>
                {' '}
                one location are more than ₹5 Crore but less than ₹50 Crore{' '}
              </SassyText>
            </Trans>
          </span>
        }
      />
      <VideoSection />
      <Coverage type="BLUS" />
      <Section background="#ffffff">
        <SectionWrapper>
          <Title className={hindiBlack}>
            <Trans i18nKey="WhyChooseBLUS.Title">
              Why opt for a <SassyText> BLUS </SassyText> policy?
            </Trans>
          </Title>
          <Left>
            <Container>
              <ul>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyChooseBLUS.Protection.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    className={hindiRegular}
                  >
                    {t('WhyChooseBLUS.Protection.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyChooseBLUS.RiskCoverage.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    className={hindiRegular}
                  >
                    {t('WhyChooseBLUS.RiskCoverage.Desc')}
                  </Text>
                </li>
                <li>
                  <Text fontWeight="bold" fontSize="16px" mfontSize="16px" className={hindiBold}>
                    <Bullet className="bullet" />
                    {t('WhyChooseBLUS.AdditionalCovers.Title')}
                  </Text>
                  <Text
                    color="#999999"
                    fontSize="16px"
                    style={{ margin: '18px 0px 0px 30px' }}
                    mfontSize="16px"
                    className={hindiRegular}
                  >
                    {t('WhyChooseBLUS.AdditionalCovers.Desc')}
                  </Text>
                </li>
              </ul>
            </Container>
            <Text fontSize="16px" mfontSize="16px" className={hindiRegular}>
              <Trans i18nKey="WhyChooseBLUS.LearnAboutBLUS">
                Learn about<strong className={hindiBold}> Bharat Laghu Udyam Suraksha (BLUS) </strong>in
                detail
                <a href="https://blog.verak.in/product-explainers-bharat-laghu-udyam-suraksha-blus-policy-amp/">
                  <SassyText>HERE</SassyText>
                </a>
              </Trans>
            </Text>
            <Text
              fontSize="16px"
              style={{ margin: '40px 0px' }}
              mfontSize="16px"
              className={hindiRegular}
            >
              <Trans i18nKey="WhyChooseBLUS.OtherOptionsText">
                If your business’s total insurable assets at 
                <strong className={hindiBold}>
                  any one location are worth more than ₹50 Crore or less than ₹5
                  Crore 
                </strong> 
                , you may want to consider opting for a 
              </Trans>
              <Link to="/fire/sfsp">
                <SassyText type="secondary" className={hindiRegular}>
                  {t('SFSPFullForm')} (SFSP)
                </SassyText>
              </Link> 
              , {t('WhyChooseBLUS.Or')} {t('WhyChooseBLUS.A')} {' '}
              <Link to="/fire/bsus">
                <SassyText type="secondary" className={hindiRegular}>
                {t('BSUSFullForm')} (BSUS)
                </SassyText> {' '}
              </Link> 
              {t('WhyChooseBLUS.InsurancePolicy')}!
            </Text>
            <Button wide label={t('CTALabels.GetAQuote')} onClick={() => handleGetQuoteClick("Clicked-Down- 'Get a Quote' - BLUS LP")} className={hindiBold} />
          </Left>
        </SectionWrapper>
      </Section>
      <HelpSection />
      <Divider />
      <Footer />
    </>
  );
};

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: {
          in: ["blus", "index", "common", "navbar", "footer", "getQuoteModal", "coverageSection", "helpSection", "videoSection"]
        }, 
        language: {eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Left = styled.div`
  text-align: left;

  @media screen and (max-width: 768px) {
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const Container = styled.div`
  display: flex;
  align-items: start;
  margin: 20px 0px;

  ul {
    margin-top: 40px;
  }

  .bullet {
    margin-left: -20px;
    margin-right: 35px;
  }

  li {
    color: #999999;
    margin-left: 24px;
    margin-bottom: 24px;
    list-style: none;
  }
`;

export default BLUS;
